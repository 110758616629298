import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HOME',
    component: Home,
  },
  {
    path: '/index.php',
    name: 'BOT HOME',
    component: Home,
  },
  {
    path: '/index.html',
    name: 'INDEX HOME',
    component: Home,
  },
  {
    path: '/about',
    name: 'ABOUT',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/video_center',
    name: 'TECH',
    component: () =>
      import(/* webpackChunkName: "video_center" */ '../views/VideoCenter.vue'),
  },
  {
    path: '/video/show/:id',
    name: 'TECH POST',
    component: () =>
      import(/* webpackChunkName: "video_show" */ '../views/VideoPage.vue'),
  },
  {
    path: '/news/list/:page',
    name: 'NEWS',
    component: () =>
      import(/* webpackChunkName: "news_list" */ '../views/NewsList.vue'),
  },
  {
    path: '/news/show/:id',
    name: 'NEWS POST',
    component: () =>
      import(/* webpackChunkName: "news_page" */ '../views/NewsPage.vue'),
  },
  {
    path: '/contact',
    name: 'CONTACT',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/product/list/:type',
    name: 'PRODUCTS',
    component: () =>
      import(/* webpackChunkName: "product_list" */ '../views/ProductList.vue'),
  },
  {
    path: '/product/show/:id',
    name: 'PRODUCT DETAIL',
    component: () =>
      import(/* webpackChunkName: "product_show" */ '../views/ProductShow.vue'),
  },
  {
    path: '/search',
    name: 'SEARCH RESULT',
    component: () =>
      import(
        /* webpackChunkName: "search_result" */ '../views/SearchResult.vue'
      ),
  },
  {
    path: '/send_order',
    name: 'INQUIRY',
    component: () =>
      import(/* webpackChunkName: "send_order" */ '../views/SendOrder.vue'),
  },
  {
    path: '/error_page',
    name: 'ErrorPage',
    component: () =>
      import(/* webpackChunkName: "error_page" */ '../views/ErrorPage.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  routes,
  base: '/',
  mode: 'history',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

export default router;
