export default {
  methods: {
    GetFullPath(path) {
      let base_url = "https://boba.yongxin-design.com/api/portal";
      if (path.indexOf("/img/") == 0 || path.indexOf("/image/") == 0) {
        return base_url + path;
      } else {
        return path;
      }
    },
  },
};
