var default_meta = {
  title: "Eco-friendly Tableware",
  content:
    "ECO SHENG JIA TECHNOLOGY CO.,LTD. is one of the leading bio-products, straws, and packaging manufacturers in Taiwan.",
  image: "https://boba.yongxin-design.com/img/share.jpg",
};

export function GetMetaData(title, content, image) {
  title = title == "" ? default_meta.title : title;
  return {
    title: title + " | ECO SHENG JIA TECHNOLOGY",
    meta: [
      {
        property: "og:description",
        content: content == "" ? default_meta.content : content,
        vmid: "og:description",
      },
      {
        property: "twitter:description",
        content: content == "" ? default_meta.content : content,
        vmid: "twitter:description",
      },
      {
        property: "description",
        content: content == "" ? default_meta.content : content,
        vmid: "description",
      },
      {
        property: "og:image",
        content: image == "" ? default_meta.image : image,
        vmid: "og:image",
      },
      {
        property: "apple-mobile-web-app-title",
        content: title + " | ECO SHENG JIA TECHNOLOGY",
        vmid: "apple-mobile-web-app-title",
      },
      {
        property: "application-name",
        content: title + " | ECO SHENG JIA TECHNOLOGY",
        vmid: "application-name",
      },
      {
        property: "og:site_name",
        content: title + " | ECO SHENG JIA TECHNOLOGY",
        vmid: "og:site_name",
      },
      {
        property: "og:type",
        content: "website",
        vmid: "og:type",
      },
      {
        property: "og:locale",
        content: "zh_tw",
        vmid: "og:locale",
      },
    ],
  };
}
