<template src="./template.html"></template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu: [
        {
          title: "ABOUT",
          link: "/about",
        },
        {
          title: "PRODUCTS",
          link: "/product/list/all",
        },
        {
          title: "TECH",
          link: "/video_center",
        },
        {
          title: "NEWS",
          link: "/news/list/1",
        },
        {
          title: "CONTACT",
          link: "/contact",
        },
      ],
      menu_1_status: false,
      menu_2_status: false,
    };
  },
  computed: {
    info() {
      return this.$store.state.company_info;
    },
  },
};
</script>