<template src="./template.html"></template>

<script>
export default {
  name: "MainNav",
  data() {
    return {
      menu_status: false,
      menu: [
        {
          title: "ABOUT",
          link: "/about",
          type: "item",
        },
        {
          title: "PRODUCTS",
          link: "/product/list/all/",
          type: "products",
        },
        {
          title: "TECH",
          link: "/video_center",
          type: "item",
        },
        {
          title: "NEWS",
          link: "/news/list/1",
          type: "item",
        },
        {
          title: "CONTACT US",
          link: "/contact",
          type: "item",
        },
      ],
      key_word: "",
      drop_down_menu: false,
    };
  },
  methods: {
    OpenShopCart() {
      this.$emit("open-shop-cart");
    },
    Search() {
      this.$router.push(`/search?name=${this.key_word}`);
      this.key_word = "";
    },
    GetFirstSeries(ProductDir2ID) {
      let series = this.series.filter(
        (item) => item.ProductDir2ID == ProductDir2ID
      );
      return series.length > 0 ? series[0].ProductDirID : "";
    },
    HoverDropDownButton(val) {
      if (window.innerWidth >= 768) {
        this.drop_down_menu = val;
      }
    },
    ClickDropDownButton() {
      if (window.innerWidth <= 768) {
        this.drop_down_menu = !this.drop_down_menu;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.drop_down_menu = false;
    });
  },
  computed: {
    category() {
      return this.$store.state.category;
    },
    series() {
      return this.$store.state.series;
    },
  },
};
</script>