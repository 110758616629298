<template src="./template.html"></template>

<script>
export default {
  name: "Breadcrumbs",
  data() {
    return {
      router_list: [],
    };
  },
  methods: {
    GetSeries() {
      if (this.$route.params.type == "all") {
        return "ALL PRODUCTS";
      } else if (this.$route.params.type == "hot") {
        return "RECOMMEND PRODUCTS";
      } else if (this.$route.params.type == "news") {
        return "NEW PRODUCTS";
      } else {
        let tmp_series = this.$store.state.series.filter(
          (item) => item.ProductDirID == this.$route.params.type
        );
        if (tmp_series.length <= 0) {
          return "";
        } else {
          let category = this.$store.state.category.filter(
            (item) => item.ProductDir2ID == tmp_series[0].ProductDir2ID
          )[0].Title;
          return category + "-" + tmp_series[0].Title;
        }
      }
    },
    GetProduct() {
      let tmp_product = this.$store.state.products.filter(
        (item) => item.ProductID == this.$route.params.id
      );
      return tmp_product.length > 0 ? tmp_product[0].Title : "";
    },
    GetProductSeries() {
      if (this.GetProduct() != "") {
        let tmp_product = this.$store.state.products.filter(
          (item) => item.ProductID == this.$route.params.id
        )[0];
        let tmp_series = this.$store.state.series.filter(
          (item) => item.ProductDirID == tmp_product.ProductDirID
        );
        if (tmp_series.length <= 0) {
          return ["", ""];
        } else {
          let category = this.$store.state.category.filter(
            (item) => item.ProductDir2ID == tmp_series[0].ProductDir2ID
          )[0].Title;
          return [
            tmp_series[0].ProductDirID,
            category + "-" + tmp_series[0].Title,
          ];
        }
      } else {
        return ["", ""];
      }
    },
  },
  watch: {
    $route() {
      console.log(this.$route.params.type);
      this.router_list = this.$route.matched;
    },
  },
  computed: {},
  mounted() {
    this.router_list = this.$route.matched;
  },
};
</script>