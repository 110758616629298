export default {
    methods: {
        GA_AddToCart(product) {
            let data = {
                event: 'add_to_cart',
                category: '加入購物車', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "加入購物車", // 標籤 字元(選填)
                items: [{
                    name: product.Title,
                    quantity: 1,
                    promotion_name: "一般消費",
                    brand: "嘉特科技有限公司",
                    price: 0,
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
        GA_RemoveFromCart(product) {
            let data = {
                event: 'remove_from_cart',
                category: '商品從購物車移除', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "商品從購物車移除", // 標籤 字元(選填)
                items: [{
                    name: product.Title,
                    quantity: 1,
                    promotion_name: "一般消費",
                    brand: "嘉特科技有限公司",
                    price: 0,
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
        GA_SelectItem(product) {
            let data = {
                event: 'select_item',
                category: '選擇商品', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "選擇商品", // 標籤 字元(選填)
                items: [{
                    name: product.name,
                    promotion_name: "一般消費",
                    brand: "嘉特科技有限公司",
                    price: parseInt(product.price),
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
        GA_ViewItem(product) {
            let data = {
                event: 'view_item',
                category: '查看商品', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "查看商品", // 標籤 字元(選填)
                items: [{
                    name: product.Title,
                    promotion_name: "一般消費",
                    brand: "嘉特科技有限公司",
                    price: 0,
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
    }
}