<template src="./template.html"></template>

<script>
export default {
  name: "HomeAbout",
  components: {},
  data() {
    return {
      about_content: "",
      home_about_background: "",
      parallax_background: "/img/About/pallarax.jpg",
    };
  },
  methods: {
    GetBackGround() {
      let full_path = this.GetFullPath(this.home_about_background);
      return `background-image:url('${full_path}')`;
    },
    async LoadData() {
      this.$store.commit("SetLoading", true);
      let response = await this.SendData("/column", "post", {
        Data: ["home_about_content", "home_about_background"],
      });
      this.$store.commit("SetLoading", false);
      if (response != "error") {
        this.home_about_background = response.home_about_background.Image;
        this.about_content = response.home_about_content.Content;
      }
    },
  },
  created() {
    this.LoadData();
  },
};
</script>