<template>
  <div id="Home">
    <Carousel :carousel="carousel" />
    <Category />
    <HotProducts />
    <About />
  </div>
</template>

<script>
import Carousel from "../components/Home/Carousel/index";
import Category from "../components/Home/Category/index";
import HotProducts from "../components/Home/HotProducts/index";
import About from "../components/Home/About/index";
import { GetMetaData } from "@/common/meta";
export default {
  name: "Home",
  components: {
    Carousel,
    Category,
    HotProducts,
    About,
  },
  data() {
    return {
      about_content: "",
      carousel: [],
      meta_carousel: [],
      meta_data: null,
    };
  },
  methods: {
    async LoadAboutData() {
      this.$store.commit("SetLoading", true);
      let response = await this.SendData("/column", "post", {
        Data: ["about_content"],
      });
      this.$store.commit("SetLoading", false);
      if (response != "error") {
        this.about_content = response.about_content.Content;
      }
    },
    async LoadCarouselData() {
      this.$store.commit("SetLoading", true);
      let response = await this.GetData("/carousel");
      this.$store.commit("SetLoading", false);
      if (response != "error") {
        this.carousel = response;
        this.carousel.forEach((item) => {
          this.meta_carousel.push(this.GetFullPath(item.Image));
          this.meta_carousel.push(this.GetFullPath(item.Image2));
        });
      }
    },
  },
  created() {
    this.LoadAboutData();
    this.LoadCarouselData();
    this.meta_data = GetMetaData("", "", "");
  },
  metaInfo() {
    return this.meta_data;
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Store",
      image: this.meta_carousel,
      "@id": "https://boba.yongxin-design.com",
      name: "ECO SHENG JIA TECHNOLOGY",
      address: {
        "@type": "PostalAddress",
        streetAddress: "No. 2,Ln. 177, Longshan St., Daya Dist.",
        addressLocality: "Taichung City",
        addressRegion: "TC",
        postalCode: "428",
        addressCountry: "TW",
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "4",
          bestRating: "5",
        },
        author: {
          "@type": "Person",
          name: "林泳欣",
        },
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 24.227829758347767,
        longitude: 120.6650027334609,
      },
      url: "https://boba.yongxin-design.com",
      telephone: "+886425684441",
      servesCuisine: "Taiwan",
      openingHoursSpecification: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          opens: "08:00",
          closes: "18:00",
        },
      ],
    };
  },
};
</script>
