<template src="./template.html"></template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "HomeHotProducts",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      window_width: window.innerWidth,
    };
  },
  methods: {
    GetSeriesTitle(id) {
      let now_series = this.series.filter((item) => item.ProductDirID == id);
      return now_series.length <= 0 ? "" : now_series[0].Title;
    },
    ChangeSwiperOption() {
      let width = window.innerWidth;
      if (width < 768) {
        this.$refs.PcSwiper.$swiper.params.slidesPerView = 2;
        this.$refs.PcSwiper.$swiper.params.spaceBetween = 10;
      } else if (width < 992) {
        this.$refs.PcSwiper.$swiper.params.slidesPerView = 3;
        this.$refs.PcSwiper.$swiper.params.spaceBetween = 10;
      } else {
        this.$refs.PcSwiper.$swiper.params.slidesPerView = 5;
        this.$refs.PcSwiper.$swiper.params.spaceBetween = 20;
      }
    },
  },
  mounted() {
    this.ChangeSwiperOption();
    window.addEventListener("resize", () => {
      if (this.$route.name == "HOME") {
        this.window_width = window.innerWidth;
      }
    });
  },
  watch: {
    window_width() {
      this.ChangeSwiperOption();
    },
  },
  filters: {
    wordbreak(val) {
      let word = "";
      let count = 0;
      let max_count = 100;

      if (window.innerWidth <= 640) {
        max_count = 100;
      }
      for (let i = 0; i < val.length; i++) {
        let tmp_count = count;
        let tmp_word = word;
        count += 1;
        word += val[i];

        if (tmp_count < max_count && count > max_count) {
          word = tmp_word;
          break;
        } else if (count == max_count) {
          break;
        }
      }
      if (word == val) {
        return word;
      } else {
        return word + "...";
      }
    },
  },
  computed: {
    products() {
      return this.$store.state.products.filter((item) => item.TagHot == "Y");
    },
    series() {
      return this.$store.state.series;
    },
  },
};
</script>