<template src="./template.html"></template>

<script>
import gsap from "gsap";
export default {
  name: "MainLoading",
  data() {
    return {
      logo_tl: null,
    };
  },
  methods: {
    SetGsap() {
      this.logo_tl == null ? "" : this.logo_tl.kill();
      let logo_el = document.querySelector("#LoadingLogo");
      let logo_el_1 = logo_el.querySelector(".cls-1");
      let logo_el_2 = logo_el.querySelector(".cls-2");
      let logo_el_3 = logo_el.querySelector(".cls-3");
      let logo_el_4 = logo_el.querySelector(".cls-4");
      this.logo_tl = gsap.timeline({
        repeat: -1,
      });
      // scale and fade out
      this.logo_tl.fromTo(
        logo_el_1,
        {
          opacity: 0,
          y: 20,
          x: -40,
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          delay: 1.2,
        },
        0
      );
      this.logo_tl.fromTo(
        logo_el_2,
        {
          opacity: 0,
          y: 20,
          x: 40,
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          delay: 1.4,
        },
        0
      );
      this.logo_tl.fromTo(
        logo_el_3,
        {
          opacity: 0,
          y: 20,
          x: -40,
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          delay: 1.6,
        },
        0
      );
      this.logo_tl.fromTo(
        logo_el_4,
        {
          opacity: 0,
          y: 20,
          x: 40,
          scale: 1,
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          delay: 1.8,
        },
        0
      );
      // fade out
      this.logo_tl.to(
        logo_el_1,
        {
          opacity: 0,
          delay: 2.2,
        },
        1
      );
      this.logo_tl.to(
        logo_el_2,
        {
          opacity: 0,
          delay: 2.2,
        },
        1
      );
      this.logo_tl.to(
        logo_el_3,
        {
          opacity: 0,
          delay: 2.2,
        },
        1
      );
      this.logo_tl.to(
        logo_el_4,
        {
          opacity: 0,
          delay: 2.2,
        },
        1
      );
    },
  },
  mounted() {
    this.SetGsap();
  },
  watch: {
    loading() {
      if (this.loading) {
        this.SetGsap();
      } else {
        this.logo_tl == null ? "" : this.logo_tl.kill();
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>